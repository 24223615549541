<template>
  <div>
    <div>
      <b-form-group
        :label="$getVisibleNames('teaching.ramicro', false, 'RA Micro')"
        label-cols="0"
        label-align-sm="2"
        label-cols-md="3"
        label-cols-lg="4"
        class="pb-0 mb-0"
      >
        <b-form-select
          :options="filteredStudyUnits"
          v-model="selected_study_unit_id"
          value-field="id"
          text-field="name"
          size="sm"
        ></b-form-select>
      </b-form-group>
    </div>
    <hr class="mt-0 mb-1" />
    <template v-if="studyUnit">
      <div class="row py-0 my-0">
        <div class="col col-sm-2 col-md-3 col-lg-4 py-0 my-0">
          Resultado de Aprendizaje
        </div>
        <div class="col text-justify py-0 my-0">
          {{ studyUnit.action }}
          {{ studyUnit.content }}
          {{ studyUnit.condition }}
        </div>
      </div>
      <hr class="mt-1 mb-1" />
      <div class="mb-3" v-if="filteredEvaluationCriterias.length == 0">
        Esta {{ $getVisibleNames("teaching.ramicro", false, "RA Micro") }} no
        cuenta con
        {{
          $getVisibleNames(
            "manual.indicador_de_logro",
            true,
            "Indicadores de Logro"
          )
        }}
      </div>
      <div
        class="row py-0 my-0"
        v-for="(criteria, index) in filteredEvaluationCriterias"
        :key="criteria.id"
        v-else
      >
        <div
          :id="'index' + index"
          class="col col-sm-2 col-md-3 col-lg-4 py-0 my-0"
        >
          <b-badge class="assessment-indicator mt-1" variant="light">
            <!-- Criterio de Evaluación -->
            {{
              $getVisibleNames(
                "manual.criterio_de_evaluacion",
                false,
                "Criterio de Evaluación"
              )
            }}
            {{ index + 1 }}
          </b-badge>
        </div>
        <!-- <b-tooltip variant="secondary" class="assessment-tooltip" :target="'index' + index">
          {{competence(criteria)}}
        </b-tooltip> -->
        <div class="col text-justify py-0 my-0 mt-1">
          {{ criteria.sentence }}
        </div>
      </div>
    </template>

    <div class="row">
      <template>
        <b-table
          :items="filteredStudents"
          :fields="tableFields"
          responsive
          hover
        >
          <template #cell(first_name)="data">
            {{ data.value }}
          </template>
          <template #cell(last_name)="data">
            {{ data.value }}
          </template>
          <template #cell(score)="data" v-if="studyUnit">
            <template v-if="loading_count == 0">
              <span
                v-b-tooltip.v-secondary="`${data.field.headerTitle}`"
                :class="[isUnachievedOverall(data.item.id) ? 'red-cell' : '']"
              >
                {{ getResultAchievement(data.item.id) }}
              </span>
            </template>
            <template v-else> Cargando... </template>
          </template>
          <template #cell()="data" v-if="loading_count == 0">
            <template v-if="data.field.key.startsWith('criteria')">
              <span
                v-b-tooltip.v-secondary="`${data.field.headerTitle}`"
                class="assesment-cell"
                :class="[
                  isUnachievedd(data.field.key.split(' ')[1], data.item.id)
                    ? 'red-cell'
                    : '',
                ]"
              >
                {{
                  getCriteriaAchievement(
                    data.field.key.split(" ")[1],
                    data.item.id
                  )
                }}
              </span>
            </template>
          </template>
        </b-table>
      </template>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { round } from "@/utils/utils";

export default {
  name: "Achievements",
  props: {
    section_id: {
      type: Number,
      required: true,
    },
    reload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected_study_unit_id: null,
      learning_result_achievements: [],
      loading_count: 0,
    };
  },
  computed: {
    ...mapGetters({
      sections: names.SECTIONS,
      evaluations: names.EVALUATIONS,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      users: names.USERS,
      profile_competences: names.PROFILE_COMPETENCES,
      studyUnits: names.STUDY_UNITS,
      evaluationCriterias: names.EVALUATION_CRITERIAS,
      matters: names.MATTERS,
      competences: names.COMPETENCES,
    }),
    section() {
      return this.sections.find((x) => x.id == this.section_id);
    },
    matter() {
      if (!this.section) return null;
      return this.egress_profiles_matters.find(
        (x) => x.id == this.section.egress_profile_matter
      );
    },
    oldMatter() {
      if (!this.matter) return null;
      return this.matters.find((x) => x.id == this.matter.matter);
    },
    sectionEvaluations() {
      return this.evaluations.filter((x) => x.section == this.section_id);
    },
    filteredStudents() {
      let list = [];
      if (!this.section) return list;
      this.section.students.forEach((user_id) => {
        const user = this.users.find((x) => x.id == user_id);
        if (user) list.push(user);
      });
      return list;
    },
    filteredStudyUnits() {
      let list = [];
      if (!this.oldMatter) return list;
      this.oldMatter.study_units.forEach((study_unit_id) => {
        const study_unit = this.studyUnits.find((x) => x.id == study_unit_id);
        if (study_unit) {
          list.push(study_unit);
        }
      });
      list.forEach((x) => {
        if (!x.name.includes(`Unidad ${x.order}:`)) {
          x.name = `Unidad ${x.order}: ${x.name}`;
        }
      });
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
    },
    studyUnit() {
      if (!this.selected_study_unit_id) return null;
      return this.filteredStudyUnits.find(
        (x) => x.id == this.selected_study_unit_id
      );
    },
    filteredEvaluationCriterias() {
      if (!this.studyUnit) return [];
      return this.evaluationCriterias.filter(
        (x) => x.study_unit == this.studyUnit.id
      );
    },
    tableFields() {
      let fields = [
        { key: "first_name", label: "Nombres", sortable: true },
        { key: "last_name", label: "Apellidos", sortable: true },
        {
          key: "score",
          label: "Logro de Resultado",
          headerTitle: this.studyUnit
            ? this.studyUnit.sentence
            : "Seleccione " +
              this.$getVisibleNames("teaching.ramicro", false, "RA Micro"),
        },
      ];
      this.filteredEvaluationCriterias.forEach((criteria, index) => {
        fields.push({
          key: "criteria " + criteria.id,
          label:
            "Logro en " +
            this.$getVisibleNames(
              "manual.criterio_de_evaluacion",
              false,
              "Criterio de Evaluación"
            ) +
            " " +
            (index + 1),
          headerTitle: criteria.sentence,
        });
      });
      return fields;
    },
  },
  methods: {
    fetchLearningResultAchievement(user_id, study_unit_id) {
      this.loading_count += 1;
      if (study_unit_id) {
        this.$restful
          .Get(
            `/evaluation/learning-result-achievement/?student=${user_id}&learning_result=${study_unit_id}`
          )
          .then((response) => {
            const index = this.learning_result_achievements.findIndex(
              (x) => x.user_id == user_id
            );
            if (index == -1) {
              this.learning_result_achievements.push(response);
            } else {
              this.learning_result_achievements.splice(index, 1, response);
            }
            this.loading_count -= 1;
          });
      }
    },
    getResultAchievement(user_id) {
      const achievement = this.learning_result_achievements.find(
        (x) => x.user_id == user_id
      );
      if (achievement && achievement.achievement_percentage)
        return String(round(achievement.achievement_percentage)) + " %";
      return "Sin medición";
    },
    getCriteriaAchievement(criteria_id, user_id) {
      const achievement = this.learning_result_achievements.find(
        (x) => x.user_id == user_id
      );
      if (achievement) {
        const criteria = achievement.evaluation_criteria_achievements.find(
          (x) => x.evaluation_criteria == criteria_id
        );
        if (criteria) {
          return String(round(criteria.achievement_percentage)) + " %";
        }
      }
      return "Sin medición";
    },
    isUnachievedd(criteria_id, user_id) {
      const achievement = this.learning_result_achievements.find(
        (x) => x.user_id == user_id
      );
      if (achievement) {
        const criteria = achievement.evaluation_criteria_achievements.find(
          (x) => x.evaluation_criteria == criteria_id
        );

        if (criteria) {
          if (round(criteria.achievement_percentage) < 60) {
            return true;
          } else return false;
        }
      }
    },
    isUnachievedOverall(user_id) {
      const achievement = this.learning_result_achievements.find(
        (x) => x.user_id == user_id
      );
      if (
        (achievement && achievement.achievement_percentage) < 60 &&
        (achievement && achievement.achievement_percentage) != null
      ) {
        return true;
      } else return false;
    },
    // competence(indicator) {
    //   if (this.competences.find((x) => x.id == indicator.competence).type == 1){
    //     return "Competencia Disciplinaria"
    //   }
    //   if (this.competences.find((x) => x.id == indicator.competence).type == 2){
    //     return "Competencia Profesional"
    //   }
    //   return "Competencia Genérica"
    // },
  },
  watch: {
    studyUnit() {
      if (this.studyUnit) {
        this.filteredStudents.forEach((student) => {
          this.fetchLearningResultAchievement(student.id, this.studyUnit.id);
        });
      }
    },
    reload() {
      if (this.studyUnit) {
        this.filteredStudents.forEach((student) => {
          this.fetchLearningResultAchievement(student.id, this.studyUnit.id);
        });
      }
    },
  },
  created() {
    this.$store
      .dispatch(names.FETCH_SECTION, this.section_id)
      .then((section) => {
        this.$store
          .dispatch(
            names.FETCH_EGRESS_PROFILE_MATTER,
            section.egress_profile_matter
          )
          .then((response) => {
            this.$store.dispatch(
              names.FETCH_COMPETENCES,
              response.egress_profile
            );
            this.$store
              .dispatch(names.FETCH_MATTER, response.matter)
              .then((matter) => {
                // if (matter.study_units.length > 0)
                // this.selected_study_unit_id = matter.study_units[0];
                let minOrderStudyUnit = 1000;
                matter.study_units.forEach((study_unit_id) => {
                  this.$store
                    .dispatch(names.FETCH_STUDY_UNIT, study_unit_id)
                    .then((response) => {
                      if (response.order < minOrderStudyUnit) {
                        minOrderStudyUnit = response.order;
                        this.selected_study_unit_id = response.id;
                      }
                    });
                });
                this.$store.dispatch(names.FETCH_EVALUATION_CRITERIAS, {
                  matter_id: response.matter,
                });
              });
          });
      });
    this.$store.dispatch(names.FETCH_EVALUATIONS_BY_SECTION, this.section_id);
  },
};
</script>

<style scoped>
.assessment-indicator {
  font-size: 14.5px;
}
.red-cell {
  color: rgb(237, 54, 54) !important;
}
.assesment-cell {
  padding: 0.4rem 1rem !important;
}
</style>